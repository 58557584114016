import React, { useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Helmet } from 'react-helmet';

import { BannerSettings } from 'src/components/molecule/Banner';
import { Translate } from 'src/components/Translate';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { useAuthContext, useRequireAuthenticated } from 'src/components/AuthProvider';
import { usePreventAgentAccess } from 'src/hooks/usePreventAgentAccess';
import { Banner } from 'src/components/molecule';
import MyAccountEditCard from 'src/components/MyAccountEditCard';
import { Path } from 'src/constants/Route';
import { PaymentDisclaimer } from 'src/components/molecule/PaymentDisclaimer';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import { useTracking } from 'src/components/TrackingContext';
import StateFarmLogo from 'src/components/agents/StateFarmLogo';
import { Split, Treatment } from 'src/constants/Split';
import { SimpleFooter } from 'src/features/footer';

const metadata = {
  title: 'Your Payment Information | Hagerty Drivers Club',
  description: 'View and edit your payment method for Hagerty Drivers Club membership.',
};

const EditCard = ({ location }: PageProps) => {
  const { isShelbyCustomer } = useShelbyCustomer();

  useTracking('my_account_edit_card');

  const i18n = {
    breadcrumb: 'breadcrumbs.myAccount.payments.text',
    breadcrumbBack: 'breadcrumbs.back',
    bannerSuccess: 'MyAccountEditCard.bannerSuccess.text',
    bannerSuccessDetails: 'MyAccountEditCard.bannerSuccessDetails.text',
    heading: 'MyAccountEditCard.heading',
    paymentTitle: 'MyAccountEditCard.paymentTitle',
    saveForm: 'MyAccountEditCard.saveForm',
    genericMsg: 'generic.error.message',
    confirmRenewalPlan: 'breadcrumbs.myAccount.confirmRenewalPlan',
    unknownProvider: 'MyAccountEditCard.unknownProvider',
    failedSetupPayment: 'MyAccountEditCard.failedSetupPayment',
    annualPrice: 'manageMembership.annual.price',
  };

  const treatments = useTreatments([Split.FeatureCustomerPaymentDisclaimer]);

  const hasCustomerPaymentDisclaimer = treatments[Split.FeatureCustomerPaymentDisclaimer].treatment === Treatment.On;
  const navFrom = location.state?.['origin'] ?? '/my-account/payments/';
  const navFromMyAccountPayments = navFrom === '/my-account/payments/';
  const { isAuthenticated } = useAuthContext();
  const [banner, setBanner] = useState<BannerSettings<undefined>>();
  usePreventAgentAccess();
  useRequireAuthenticated();

  const onSuccessNavigate = () => {
    return navigate(Path.MyAccount, {
      state: {
        nav: navFromMyAccountPayments ? undefined : true,
        msg: i18n.bannerSuccessDetails,
      },
    });
  };

  const handleCancel = () => {
    return navigate(navFrom);
  };

  return (
    <>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
      </Helmet>
      <div className="color-background-light-gray my_account_container">
        {isAuthenticated && (
          <>
            {banner?.display && <Banner banner={banner} setBanner={setBanner} />}
            <div className="container container_center page__my-account">
              <div className="status-info max-width_content">
                {navFromMyAccountPayments ? (
                  <Breadcrumbs originTransKey={i18n.breadcrumbBack} originPath={Path.MyAccount} />
                ) : (
                  <Breadcrumbs
                    originTransKey={i18n.confirmRenewalPlan}
                    originPath={'/my-account/renew-plan/confirm/'}
                    state={{ nav: true }}
                  />
                )}
                <h2 className="text-display_2 pb-4">
                  <Translate resourceKey={i18n.heading} />
                </h2>

                {hasCustomerPaymentDisclaimer && <Translate resourceKey={i18n.annualPrice} />}

                <div className="inset-s" />
                <legend className="text-heading_4">
                  <Translate resourceKey={i18n.paymentTitle} />
                </legend>
                <MyAccountEditCard onSuccess={onSuccessNavigate} onCancel={handleCancel} />

                {hasCustomerPaymentDisclaimer && <PaymentDisclaimer type="save" />}
              </div>

              <div className="inset-l" />

              {isShelbyCustomer && <StateFarmLogo />}

              <div className="inset-l" />
            </div>
          </>
        )}

        <SimpleFooter />
      </div>
    </>
  );
};

export default EditCard;
