import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Translate } from 'src/components/Translate';
import { CheckoutFormStripe } from 'src/components/CheckoutFormStripe';
import CheckoutFormMP from 'src/components/CheckoutFormMP';
import useDefaultPaymentMethod from 'src/api/useDefaultPaymentMethod';
import useHandleStripeErrors from 'src/hooks/useHandleStripeErrors';
import useProfile from 'src/api/useProfile';
import LoadingContainer from './LoadingContainer';
import { PaymentProvider } from 'src/constants/Payment';
import { useTrackingContext } from 'src/components/TrackingContext';

import { Split, Treatment } from 'src/constants/Split';
import { useTreatments } from '@splitsoftware/splitio-react';
import { isReady } from 'src/components/SplitContext/isReady';
import stripeErrorsList from 'src/helpers/stripeErrorsList';

type Props = {
  onSuccess: () => void;
  onCancel?: () => void;
};

/**
 * Setup payment flow:
 * For Stripe
 *   1. We collect user's Payment method with Stripe SDK Form, then trigger onStripeSubmit
 *   2. THEN setupStripePaymentMethod, get and set paymentIntent to state
 *   3. THEN CheckoutFormStripe will pick up paymentIntent in its handlePaymentForm
 *   4. THEN CheckoutFormStripe#handlePaymentForm will submit Stripe card with its SDK functions, and generate confirmation
 *   5. THEN confirmation gets picked up by this #processConfirmation
 *   6. THEN #processConfirmation will verifyStripePaymentMethod
 *   6. THEN upon success, #processConfirmation will navigate away
 */
const MyAccountEditCard: React.FC<Props> = ({ onSuccess }) => {
  const i18n = {
    breadcrumb: 'breadcrumbs.myAccount.payments.text',
    bannerSuccess: 'MyAccountEditCard.bannerSuccess.text',
    heading: 'MyAccountEditCard.heading',
    saveForm: 'MyAccountEditCard.saveForm',
    genericMsg: 'generic.error.message',
    confirmRenewalPlan: 'breadcrumbs.myAccount.confirmRenewalPlan',
    unknownProvider: 'MyAccountEditCard.unknownProvider',
    failedSetupPayment: 'MyAccountEditCard.failedSetupPayment',
  };
  const [confirmation, setConfirmation] = useState<Record<string, any>>({});
  const [formData, setFormData] = useState({});
  const [paymentIntent, setPaymentIntent] = useState<any>(false);
  const [submitFormError, setSubmitFormError] = useState<string>();
  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState: { isSubmitting, isValid },
    ...useFormFields
  } = useForm({ mode: 'onChange' });
  const { defaultPaymentMethod, defaultPaymentMethodLoading, defaultPaymentMethodRefetch } = useDefaultPaymentMethod();
  const { data: user } = useProfile({ fetchPolicy: 'network-only' });
  const { trackInteraction } = useTrackingContext();
  // fallback to userId/omniId
  const hagertyAccountId = user?.ghaid ? user?.ghaid : user?.id;

  const splitIsReady = isReady();
  const treatments = useTreatments([Split.MoneyPlatformIintegration]);
  const isMoneyPlatform = treatments[Split.MoneyPlatformIintegration].treatment === Treatment.On;
  const isLoading = defaultPaymentMethodLoading || !splitIsReady;
  const paymentProvider = isMoneyPlatform
    ? PaymentProvider.MoneyPlatform
    : defaultPaymentMethod?.provider
    ? defaultPaymentMethod?.provider
    : PaymentProvider.Stripe;

  function convertToValidZipCode(text) {
    if (text) return text.replace(/(\d{5})(\d{4})/, '$1-$2');
  }
  const billingAddress = defaultPaymentMethod?.billing_details
    ? {
        line1: defaultPaymentMethod?.billing_details.address.line1,
        line2: defaultPaymentMethod?.billing_details.address.line2,
        state: defaultPaymentMethod?.billing_details.address.state,
        city: defaultPaymentMethod?.billing_details.address.city,
        zip: convertToValidZipCode(defaultPaymentMethod?.billing_details.address.postal_code),
      }
    : null;

  const setStripeErrors = useHandleStripeErrors(); // using form setError

  // The address form is only used by Stripe
  const onStripeSubmit = (data) => {
    setSubmitFormError(null);
    setFormData(data);
    setPaymentIntent(true);
  };

  // #processConfirmation (the confirmation of payment method saved in provider)
  useEffect(() => {
    if (confirmation.error) {
      const { error } = confirmation;
      const message = error?.code || error?.type;
      if (
        error?.code &&
        error?.param !== 'address_zip' &&
        error?.param !== 'address_line1' &&
        error?.code !== 'prepaid_card'
      )
        setStripeErrors(confirmation.error, setError);
      else setSubmitFormError(stripeErrorsList.includes(message) ? `stripe.errors.${message}` : i18n.genericMsg);
      setPaymentIntent(null);
    } else if (confirmation.updated) {
      setPaymentIntent(null);
      defaultPaymentMethodRefetch();
      onSuccess();
    }
  }, [confirmation, paymentProvider]);

  const handleMPError = () => {
    setSubmitFormError(i18n.genericMsg);
  };

  return (
    <>
      {!isLoading ? (
        <>
          {paymentProvider === PaymentProvider.MoneyPlatform && (
            <CheckoutFormMP
              billingAddress={billingAddress}
              hagertyAccountId={hagertyAccountId}
              onError={handleMPError}
              verifyOnly
            />
          )}
          {paymentProvider === PaymentProvider.Stripe && (
            <form onSubmit={handleSubmit(onStripeSubmit)}>
              <CheckoutFormStripe
                paymentIntent={paymentIntent}
                defaultCard={defaultPaymentMethod || {}}
                setConfirmation={setConfirmation}
                formData={formData}
                useFormFields={{ errors, register, setError, ...useFormFields }}
              />
              <div className="button-group">
                <button
                  className="button button_primary button_primary--large"
                  type="submit"
                  data-cy="save-cc-button"
                  disabled={isSubmitting || !isValid || paymentIntent}
                  onClick={() => trackInteraction('button', 'Submit')}
                >
                  <Translate resourceKey={i18n.saveForm} />
                </button>
              </div>
            </form>
          )}
          {submitFormError && (
            <>
              <fieldset className="form-element__control has-error" data-cy="form-error">
                <div className="form-element__help">
                  <Translate resourceKey={submitFormError} />
                </div>
              </fieldset>
              <div className="inset-l"></div>
            </>
          )}
        </>
      ) : (
        <LoadingContainer height={200} />
      )}
    </>
  );
};

export default MyAccountEditCard;
